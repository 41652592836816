import Swal from "sweetalert2";

export async function messageSwal(title, text, type) {
    return Swal.fire({
            title,
            text,
            icon: type,
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
        })
        .then((result) => {
            return !!result.value;

        })
        .catch(() => {
            return false;
        });
}
